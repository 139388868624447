<script lang="ts" setup>
import DynamicForm from '~/components/DynamicForm.vue'

const call = ref<DeviceCall>()
const dialogRef = inject<ComputedRef<DynamicDialogInstance>>('dialogRef')
const form = ref<DynamicForm>()
const formSchema = {
  fields: [
    {
      as: 'InputText',
      label: 'To',
      name: 'to',
      rules: yup.string().required().label('To'),
    }
  ]
}
const loading = ref<boolean>(false)

onBeforeMount(() => {
  if (dialogRef?.value.data?.call) call.value = dialogRef.value.data.call
})

const handleSubmit = async (values) => {
  if (!call.value) return

  loading.value = true

  const { $api } = useNuxtApp()
  await $api('/crm/calls/transfer/', {
    method: 'POST',
    body: JSON.stringify({
      sid: call.value.parameters.CallSid,
      ...values
    })
  })
    .then(() => dialogRef?.value.close({ hideButton: true }))
    .catch(error => form.value?.setErrors(error.data))
    .finally(() => loading.value = false)
}
</script>

<template>
  <DynamicForm
    :loading="loading"
    :schema="formSchema"
    ref="form"
    @submit="handleSubmit"
  />
</template>
